
@font-face {
  font-family: "Nobile";
  src: url("./fonts/Nobile-Bold.ttf") format("woff2");
  font-weight: 500;
}

@font-face {
  font-family: "Nobile";
  src: url("./fonts/Nobile-Medium.ttf") format("woff2");
  font-weight: 600;
}

@font-face {
  font-family: "Nobile";
  src: url("./fonts/Nobile-Regular.ttf") format("woff2");
  font-weight: 400;
}

@font-face {
  font-family: "FangPing";
  src: url("./fonts/FangPing-Regular.ttf") format("woff2");
  font-weight: 400;
}

body {
  margin: 0;
  font-family:'FangPing','Nobile', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  zoom: 0.8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul,li,ol {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
  text-align: left;
}

.clear {
  clear: both;
}

*{
  box-sizing: border-box;
}