.swiper-section {
    position: relative;
}

.swiper-button-next,
.swiper-button-prev {
    width: 52px;
    height: 52px;
}

.swiper-button-next {
    right: -60px;
}

.swiper-button-next::after,
.swiper-button-prev::after {
    display: none;
}

.swiper-button-prev {
    left: -60px;
}

.swiper-content {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.swiper-wrapper {
    align-items: center;
}
@media (max-width: 768px) {

    .swiper-button-next,
    .swiper-button-prev {
        display: none;
    }
}

@media (min-width: 768px) {
    .swiper-pagination {
        display: none;
    }
}