.App {
  background-color: #fff;
}

.home-title-section {
  height: 100%;
  width: 100%;
  background: url('/public/images/img-background.png') no-repeat;
  background-size: 100% 100%;
  padding: 90px 0;
}

.home-title-section h3 {
  color: #FFF;
  font-size: 36px;
  font-family: Nobile;
  font-weight: 700;
  margin: 46px 0;
}

.home-title-intro {
  background-color: #000;
  margin-top: -50px;
  padding: 0 0 80px 0;
}

.home-content2 {
  min-height: 650px;
  width: 100%;
  background: url('../public/images/img-2.png') center no-repeat;
  background-size: contain;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
}


.home-content2-content {
  position: absolute;
  bottom: 25px;
  text-align: center;
  width: 100%;
}

.home-content2 p {
  color: #FFF;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: FangPing;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
}

.logo {
  max-height: 250px;
}

.home-title-intro p {
  color: #FFF;
  text-align: center;
  font-family: FangPing;
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
}

.home-title-section h4 {
  color: #FFF;
  font-family: Nobile;
  font-size: 36px;
  font-style: normal;
  margin: 80px 0;
  font-weight: 500;
  line-height: 70px;
}

.home-title-section h3 {
  color: #FFF;
  font-family: Nobile;
  font-size: 40px;
  font-style: normal;
  margin: 80px 0;
  font-weight: 500;
  line-height: 70px;
}


.home-content {
  text-align: center;
  padding: 80px 0;
}

.home-content img {
  max-height: 50px;
}

.home-content h4 {
  color: #000;
  text-align: center;
  font-family: Nobile;
  font-size: 32px;
  font-weight: 500;
}

.home-content p {
  color: #000;
  text-align: center;
  font-family: FangPing;
  font-weight: 600;
  font-size: 23px;
}

.home-content3 {
  text-align: center;
  padding: 60px 0 40px 0;
}

.home-content3 h4 {
  color: #000;
  text-align: center;
  font-family: Nobile;
  font-size: 32px;
  font-weight: 600;
}

.home-content3 li {
  width: 49%;
  float: left;
  border-radius: 15px;
  padding: 40px;
  box-sizing: border-box;
  margin-bottom: 34px;
}

.home-content3 span {
  color: #000;
  text-align: left;
  font-family: FangPing;
  font-size: 24px;
  font-weight: 600;
  padding: 0 20px;
}

.home-content3 p {
  color: #000;
  text-align: left;
  font-family: FangPing;
  font-size: 50px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin: 14px 0;
}

.home-content3 label {
  color: #000;
  font-family: FangPing;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  /* 187.5% */
}

.home-content4 {
  min-height: 450px;
  width: 100%;
  /* background: url('../public/images/img-3.png') center no-repeat; */
  background: url('../public/images/img-3.png') no-repeat, rgba(0, 0, 0, 0.98);
  background-size: cover;
  border-radius: 28px;
  overflow: hidden;
  position: relative;
}


li:nth-child(2n + 1) {
  margin-right: 2%;
}

li:nth-child(3n + 3) {
  margin-top: -53px !important;
}

.li-color1 {
  background: #F8FBFF;
}

.li-color2 {
  background: #F4FFFB;
  padding: 70px 40px !important;
}

.logo-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.intro-label {
  color: #000;
  text-align: center;
  font-family: FangPing;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 60px;
}

.home-content4 {
  padding: 60px 0 60px 32%;
}

.home-content4 h2 {
  color: #FFF;
  font-family: Nobile;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
}
.home-content4 h3 {
  color: #FFF;
  font-family: Nobile;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 80px;
}

.home-content4 img {
  height: 30px;
  margin-right: 20px;
}

.home-content4 p {
  color: #FFF;
  font-family: FangPing;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 50px;
  display: flex;
  align-items: center;
  margin: 5px 0;
  /* 208.333% */
}

.home-content4 .padding-p {
  padding-left: 50px;
}

h1 {
  color: #000;
  font-family: Nobile;
  font-size: 38px;
  font-style: normal;
  font-weight:500;
  line-height: 80px;
  text-align: center;
  margin: 60px 0 ;
  /* 250% */
}

.footer {
  background-color: #000;
  color:#FFF;
  padding:35px 0;
}

.footer p {
  font-size: 20px;;
}

.footer a {
  cursor: pointer;
  text-decoration:underline;
}

.home-swiper-link {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 60px 0;
}

.home-swiper-link h4{
    color: #000;
    text-align: center;
    font-family: Nobile;
    font-size: 32px;
    font-weight: 600;
    margin:20px 0;
}


.home-swiper-link a{
  color: #000;
  text-align: center;
  font-family: FangPing;
  font-size: 23px;
  font-weight: 500;
  margin-bottom: 40px;;
}

.home-swiper-link a:hover {
  color:#3dcec2;
  transform: 2s;
}


.footer a:hover{
  color:#3dcec2;
  transform: 2s;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 1500px) {
  .home-content2-label {
    margin-top: -660px;
  }

  .home-content2 p {
    font-size: 40px;
  }

}

@media (max-width: 768px) {
  .logo {
    max-height: 170px;
  }

  .home-title-section {
    padding:40px 0;
  }

  .home-title-section .MuiContainer-root {
    text-align: center;
  }

  .home-title-section h4 {
    font-size: 24px;
    margin:40px 0;
  }

  .home-title-section h3 {
    font-size: 29px;
    margin: 30px 0;
  }

  .logo-section {
    justify-content:space-between;
  }

  .home-title-intro {
    margin-top: -20px;
    padding:0 0 10px 0;
  }
  .logo-section img {
    height: 100%;
    width: 15%;
  }

  .home-content {
    padding:30px 0;
  }

  .home-content h4 {
    font-size: 24px;
  }

  .home-swiper-link a {
    font-size: 24px;
  }

  .home-content p {
    font-size: 20px;
  }

  .home-content2 {
    min-height: 280px;
  }

  .home-content2-content p {
    font-size: 18px;
  }

  .home-content3 {
    padding:20px 0;
  }

  .home-content3 h4 {
    font-size: 24px;
  }

  .home-content3 li {
    float: unset;
    width: 100%;
    padding:40px !important;
    margin-bottom: 20px !important;
    margin-top: 0 !important;
  }

  .intro-label {
    margin:  0 0 20px 0;
  }

  .home-content4 {
    min-height: 280px;
    background-size: cover;
    padding: 20px 10px;
  }

  .home-content4 h3 {
    margin: 5px 0;
    font-size: 22px;
  }

  .home-content4 p {
    font-size: 12px;
    padding-left: 30% ;
    line-height: 20px;
  }

  .padding-p {
    padding-left: calc(30% + 26px) !important;
  }
  .home-content4 img {
    height: 16px;
    margin-right: 10px;
  }

  .home-content4 h2 {
    font-size: 20px;
    line-height: 30px;
  }
  h1 {
    font-size: 28px;
    line-height: 35px;
    margin: 60px 0;
  }

  .home-swiper-link {
    padding: 30px 0 ;
  }
}